import { LicenseInfo } from '@mui/x-license';
import React, { useContext, useEffect, useMemo, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import '@fontsource/questrial';
import '@fontsource/roboto';

import LoadingSpinner from './shared/components/UiElements/LoadingSpinner';
import UserContext from './shared/contexts/user-context';
import styles from './App.module.css';
LicenseInfo.setLicenseKey('fea6d74d95e74de496c1ac541de33b6dTz05MTA2NSxFPTE3NDc5ODY2MzIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const AgreementStatus = React.lazy(() => import('./agreementStatuses/pages/AgreementStatus'));
const AgreementStatuses = React.lazy(() => import('./agreementStatuses/pages/AgreementStatuses'));
const AgreementType = React.lazy(() => import('./agreementTypes/pages/AgreementType'));
const AgreementTypes = React.lazy(() => import('./agreementTypes/pages/AgreementTypes'));
const BankTransactions = React.lazy(() => import('./bankTransactions/pages/BankTransactions'));
const BankTransactionType = React.lazy(() => import('./bankTransactionTypes/pages/BankTransactionType'));
const BankTransactionTypes = React.lazy(() => import('./bankTransactionTypes/pages/BankTransactionTypes'));
const BankTransactionsUpload = React.lazy(() => import('./bankTransactions/pages/BankTransactionsUpload'));
const BulkPrint = React.lazy(() => import('./reports/pages/BulkPrint'));
const BusinessArea = React.lazy(() => import('./businessAreas/pages/BusinessArea'));
const BusinessAreas = React.lazy(() => import('./businessAreas/pages/BusinessAreas'));
const BusinessPartner = React.lazy(() => import('./businessPartners/pages/BusinessPartner'));
const BusinessPartners = React.lazy(() => import('./businessPartners/pages/BusinessPartners'));
const NewBusinessPartner = React.lazy(() => import('./businessPartners/pages/NewBusinessPartner'));
const BusinessPartnerBalances = React.lazy(() => import('./businessPartnerBalances/pages/BusinessPartnerBalances'));
const BusinessSubarea = React.lazy(() => import('./businessSubareas/pages/BusinessSubarea'));
const BusinessSubareas = React.lazy(() => import('./businessSubareas/pages/BusinessSubareas'));
const Candidate = React.lazy(() => import('./candidates/pages/Candidate'));
const Candidates = React.lazy(() => import('./candidates/pages/Candidates'));
const NewCandidate = React.lazy(() => import('./candidates/pages/NewCandidate'));
const ExternalCandidate = React.lazy(() => import('./candidates/pages/ExternalCandidate'));
const ClosuresReview = React.lazy(() => import('./closuresReviews/pages/ClosuresReview'));
const Company = React.lazy(() => import('./companies/pages/Company'));
const Companies = React.lazy(() => import('./companies/pages/Companies'));
const ConsolidatedExports = React.lazy(() => import('./reports/pages/ConsolidatedExports'));
const CreditCard = React.lazy(() => import('./creditCards/pages/CreditCard'));
const CreditCards = React.lazy(() => import('./creditCards/pages/CreditCards'));
const CurrencyNominal = React.lazy(() => import('./currencyNominals/pages/CurrencyNominal'));
const CurrencyNominals = React.lazy(() => import('./currencyNominals/pages/CurrencyNominals'));
const DataChecks = React.lazy(() => import('./reports/pages/DataChecks'));
const Discounts = React.lazy(() => import('./discounts/pages/Discounts'));
const Employee = React.lazy(() => import('./employees/pages/Employee'));
const Employees = React.lazy(() => import('./employees/pages/Employees'));
const NewEmployee = React.lazy(() => import('./employees/pages/NewEmployee'));
const EnergyMeter = React.lazy(() => import('./energyMeters/pages/EnergyMeter'));
const EnergyMeters = React.lazy(() => import('./energyMeters/pages/EnergyMeters'));
const FoodDeliveryService = React.lazy(() => import('./foodDeliveryServices/pages/FoodDeliveryService'));
const FoodDeliveryServices = React.lazy(() => import('./foodDeliveryServices/pages/FoodDeliveryServices'));
const FundTransfers = React.lazy(() => import('./fundTransfers/pages/FundTransfers'));
const FxRates = React.lazy(() => import('./fxRates/pages/FxRates'));
const Home = React.lazy(() => import('./users/pages/Home'));
const HrDocumentFile = React.lazy(() => import('./hrDocumentFiles/pages/HrDocumentFile'));
const HrDocumentFiles = React.lazy(() => import('./hrDocumentFiles/pages/HrDocumentFiles'));
const HrDocumentTemplate = React.lazy(() => import('./hrDocumentTemplates/pages/HrDocumentTemplate'));
const HrDocumentTemplates = React.lazy(() => import('./hrDocumentTemplates/pages/HrDocumentTemplates'));
const NewHrDocumentTemplate = React.lazy(() => import('./hrDocumentTemplates/pages/NewHrDocumentTemplate'));
const HrDocumentType = React.lazy(() => import('./hrDocumentTypes/pages/HrDocumentType'));
const HrDocumentTypes = React.lazy(() => import('./hrDocumentTypes/pages/HrDocumentTypes'));
const IncomingRecords = React.lazy(() => import('./incomingRecords/pages/IncomingRecords'));
const InternalTransfers = React.lazy(() => import('./internalTransfers/pages/InternalTransfers'));
const Inventories = React.lazy(() => import('./inventories/pages/Inventories'));
const JobBucket = React.lazy(() => import('./jobBuckets/pages/JobBucket'));
const JobBuckets = React.lazy(() => import('./jobBuckets/pages/JobBuckets'));
const JobGroup = React.lazy(() => import('./jobTitles/pages/JobGroup'));
const JobGroups = React.lazy(() => import('./jobTitles/pages/JobGroups'));
const JobTitle = React.lazy(() => import('./jobTitles/pages/JobTitle'));
const JobTitles = React.lazy(() => import('./jobTitles/pages/JobTitles'));
const Login = React.lazy(() => import('./users/pages/Login'));
const DayClosure = React.lazy(() => import('./dayClosures/pages/DayClosure'));
const DayClosures = React.lazy(() => import('./dayClosures/pages/DayClosures'));
const NewDayClosure = React.lazy(() => import('./dayClosures/pages/NewDayClosure'));
const MultiSportCards = React.lazy(() => import('./uploads/pages/MultiSportCards'));
const NavigationBar = React.lazy(() => import('./shared/components/Navigation/NavigationBar'));
const NewPassword = React.lazy(() => import('./users/pages/NewPassword'));
const OperationalFinances = React.lazy(() => import('./operationalFinances/pages/OperationalFinances'));
const OutgoingRecords = React.lazy(() => import('./outgoingRecords/pages/OutgoingRecords'));
const Outlet = React.lazy(() => import('./outlets/pages/Outlet'));
const Outlets = React.lazy(() => import('./outlets/pages/Outlets'));
const OutletExports = React.lazy(() => import('./reports/pages/OutletExports'));
const OutletDatedExports = React.lazy(() => import('./reports/pages/OutletDatedExports'));
const NewOutlet = React.lazy(() => import('./outlets/pages/NewOutlet'));
const OutletArea = React.lazy(() => import('./outletAreas/pages/OutletArea'));
const OutletAreas = React.lazy(() => import('./outletAreas/pages/OutletAreas'));
const PasswordReset = React.lazy(() => import('./users/pages/PasswordReset'));
const PaymentService = React.lazy(() => import('./paymentServices/pages/PaymentService'));
const PaymentServices = React.lazy(() => import('./paymentServices/pages/PaymentServices'));
const Periods = React.lazy(() => import('./periods/pages/Periods'));
const Plumbing = React.lazy(() => import('./users/pages/Plumbing'));
const Preshifts = React.lazy(() => import('./preshifts/pages/Preshifts'));
const Profile = React.lazy(() => import('./users/pages/Profile'));
const ReportAmountsPaid = React.lazy(() => import('./reports/pages/ReportAmountsPaid'));
const ReportCosts = React.lazy(() => import('./reports/pages/ReportCosts'));
const RetroactiveChanges = React.lazy(() => import('./retroactiveChanges/pages/RetroactiveChanges'));
const Role = React.lazy(() => import('./roles/pages/Role'));
const Roles = React.lazy(() => import('./roles/pages/Roles'));
const NewRole = React.lazy(() => import('./roles/pages/NewRole'));
const RoleX = React.lazy(() => import('./roles/pages/RoleX'));
const RolesX = React.lazy(() => import('./roles/pages/RolesX'));
const NewRoleX = React.lazy(() => import('./roles/pages/NewRoleX'));
const SalaryScheme = React.lazy(() => import('./salarySchemes/pages/SalaryScheme'));
const SalarySchemes = React.lazy(() => import('./salarySchemes/pages/SalarySchemes'));
const Settlements = React.lazy(() => import('./settlements/pages/Settlements'));
const ShiftLength = React.lazy(() => import('./shiftLengths/pages/ShiftLength'));
const ShiftLengths = React.lazy(() => import('./shiftLengths/pages/ShiftLengths'));
const Support = React.lazy(() => import('./users/pages/Support'));
const TeamFundTransfers = React.lazy(() => import('./teamFundTransfers/pages/TeamFundTransfers'));
const TerminalDevice = React.lazy(() => import('./terminalDevices/pages/TerminalDevice'));
const TerminalDevices = React.lazy(() => import('./terminalDevices/pages/TerminalDevices'));
const Timesheets = React.lazy(() => import('./timesheets/pages/Timesheets'));
const TipsAllocation = React.lazy(() => import('./tipsAllocations/pages/TipsAllocation'));
const TipsAllocations = React.lazy(() => import('./tipsAllocations/pages/TipsAllocations'));
const User = React.lazy(() => import('./users/pages/User'));
const Users = React.lazy(() => import('./users/pages/Users'));
const Utilities = React.lazy(() => import('./utilities/pages/Utilities'));
const NewUser = React.lazy(() => import('./users/pages/NewUser'));
const Vouchers = React.lazy(() => import('./vouchers/pages/Vouchers'));
const VoucherAmount = React.lazy(() => import('./voucherAmounts/pages/VoucherAmount'));
const VoucherAmounts = React.lazy(() => import('./voucherAmounts/pages/VoucherAmounts'));
const WeeklyHoursAverage = React.lazy(() => import('./weeklyHoursAverages/pages/WeeklyHoursAverage'));
const WeeklyHoursAverages = React.lazy(() => import('./weeklyHoursAverages/pages/WeeklyHoursAverages'));
const WorkAnniversaries = React.lazy(() => import('./reports/pages/WorkAnniversaries'));
const WorkTeam = React.lazy(() => import('./workTeams/pages/WorkTeam'));
const WorkTeams = React.lazy(() => import('./workTeams/pages/WorkTeams'));

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light' ?
      {
        primary: {
          main: '#f05a5a',
          light: '#de7f7f',
          dark: '#cf2e2e',
          contrastText: '#fff'
        },
        secondary: {
          main: '#3f51b5',
          light: '#7986cb',
          dark: '#303f9f',
          contrastText: '#fff'
        }
      } :
      {
        primary: {
          main: '#f05a5a',
          light: '#de7f7f',
          dark: '#cf2e2e',
          contrastText: '#fff'
        },
        secondary: {
          main: '#3f51b5',
          light: '#7986cb',
          dark: '#303f9f',
          contrastText: '#fff'
        }
      }
    )
  },
  typography: {
    allVariants: {
      fontFamily: [
        'Questrial',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(',')
    }
  }
});

const App = () => {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const { isLoggedIn, isAdmin, masterDataPermissions, featurePermissions, userSettings, toggleDarkMode } = useContext(UserContext);

  const renderHomePage = () => {
    if (isAdmin || featurePermissions?.find(x => x.feature === 'preshifts')?.permissions.canReadAny) {
      return <Preshifts />;
    } else if (featurePermissions?.find(x => ['individualClosures', 'floorClosures', 'managerClosures'].includes(x.feature))?.permissions.canReadAny) {
      return <DayClosures />;
    } else if (featurePermissions?.find(x => ['floorClosures', 'managerClosures'].includes(x.feature))?.permissions.canReadAny) {
      return <ClosuresReview />;
    } else if (featurePermissions?.find(x => x.feature === 'timesheets')?.permissions.canReadAny) {
      return <Timesheets />;
    } else if (featurePermissions?.find(x => x.feature === 'settlements')?.permissions.canReadAny) {
      return <Settlements />;
    } else if (featurePermissions?.find(x => x.feature === 'tipsAllocations')?.permissions.canReadAny) {
      return <TipsAllocations />;
    } else if (masterDataPermissions?.canRead.includes('employees') || featurePermissions?.find(x => x.feature === 'employees')?.permissions.canReadAny) {
      return <Employees />;
    } else if (masterDataPermissions?.canRead.includes('bankTransactions')) {
      return <BankTransactions />;
    } else {
      return <Home />;
    }
  };

  useEffect(() => {
    if (isLoggedIn && userSettings?.isDarkMode == null) {
      toggleDarkMode(defaultDark);
    }
  }, [isLoggedIn, userSettings?.isDarkMode, defaultDark, toggleDarkMode]);

  const theme = useMemo(() => createTheme(getDesignTokens(userSettings ? (userSettings.isDarkMode ? 'dark' : 'light') :
    (defaultDark ? 'dark' : 'light')))
  , [userSettings, defaultDark]);

  const routes = isLoggedIn ?
    <Switch>
      <Route path='/' exact>
        {() => renderHomePage()}
      </Route>
      {(isAdmin || masterDataPermissions?.canRead.includes('agreementStatuses')) &&
        <Route path='/agreementStatuses' exact>
          <AgreementStatuses />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('agreementStatuses')) &&
        <Route path='/agreementStatuses/:agreementStatusId' exact>
          <AgreementStatus />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('agreementTypes')) &&
        <Route path='/agreementTypes' exact>
          <AgreementTypes />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('agreementTypes')) &&
        <Route path='/agreementTypes/:agreementTypeId' exact>
          <AgreementType />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('bankTransactions')) &&
        <Route path='/bankTransactions' exact>
          <BankTransactions />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('bankTransactions')) &&
        <Route path='/bankTransactionTypes/:bankTransactionTypeId' exact>
          <BankTransactionType />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('bankTransactions')) &&
        <Route path='/bankTransactionTypes' exact>
          <BankTransactionTypes />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canCreate.includes('bankTransactions')) &&
        <Route path='/bankTransactionsUpload' exact>
          <BankTransactionsUpload />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('hrDocuments')) &&
        <Route path='/bulkPrint' exact>
          <BulkPrint />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('businessAreas')) &&
        <Route path='/businessAreas' exact>
          <BusinessAreas />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('businessAreas')) &&
        <Route path='/businessAreas/:businessAreaId' exact>
          <BusinessArea />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('businessPartners')) &&
        <Route path='/businessPartners' exact>
          <BusinessPartners />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canCreate.includes('businessPartners')) &&
        <Route path='/businessPartners/new' exact>
          <NewBusinessPartner />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('businessPartners')) &&
        <Route path='/businessPartners/:businessPartnerId' exact>
          <BusinessPartner />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'businessPartnerBalances')?.permissions.canReadAny) &&
        <Route path='/businessPartnerBalances' exact>
          <BusinessPartnerBalances />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('businessSubareas')) &&
        <Route path='/businessSubareas' exact>
          <BusinessSubareas />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('businessSubareas')) &&
        <Route path='/businessSubareas/:businessSubareaId' exact>
          <BusinessSubarea />
        </Route>
      }
      {(isAdmin ||
        masterDataPermissions?.canRead.includes('employees') || featurePermissions?.find(x => x.feature === 'employees')?.permissions.canReadAny ||
        masterDataPermissions?.canRead.includes('candidates') || featurePermissions?.find(x => x.feature === 'candidates')?.permissions.canReadAny) &&
        <Route path='/candidates' exact>
          <Candidates />
        </Route>
      }
      {(isAdmin ||
        masterDataPermissions?.canCreate.includes('employees') || featurePermissions?.find(x => x.feature === 'employees')?.permissions.canCreateAny ||
        masterDataPermissions?.canCreate.includes('candidates') || featurePermissions?.find(x => x.feature === 'candidates')?.permissions.canCreateAny) &&
        <Route path='/candidates/new' exact>
          <NewCandidate />
        </Route>
      }
      {(isAdmin ||
        masterDataPermissions?.canRead.includes('employees') || featurePermissions?.find(x => x.feature === 'employees')?.permissions.canReadAny ||
        masterDataPermissions?.canRead.includes('candidates') || featurePermissions?.find(x => x.feature === 'candidates')?.permissions.canReadAny) &&
        <Route path='/candidates/:candidateId' exact>
          <Candidate />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => ['floorClosures', 'managerClosures'].includes(x.feature))?.permissions.canReadAny) &&
        <Route path='/closuresReview' exact>
          <ClosuresReview />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('companies')) &&
        <Route path='/companies' exact>
          <Companies />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('companies')) &&
        <Route path='/companies/:companyId' exact>
          <Company />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('advancedReports')) &&
        <Route path='/consolidatedExports' exact>
          <ConsolidatedExports />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('bankTransactions')) &&
        <Route path='/creditCards' exact>
          <CreditCards />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('bankTransactions')) &&
        <Route path='/creditCards/:creditCardId' exact>
          <CreditCard />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('currencyNominals')) &&
        <Route path='/currencyNominals' exact>
          <CurrencyNominals />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('currencyNominals')) &&
        <Route path='/currencyNominals/:currencyNominalId' exact>
          <CurrencyNominal />
        </Route>
      }
      <Route path='/dataChecks' exact>
        <DataChecks />
      </Route>
      {(isAdmin || featurePermissions?.find(x => ['individualClosures', 'floorClosures', 'managerClosures'].includes(x.feature))?.permissions.canReadAny) &&
        <Route path='/dayClosures' exact>
          <DayClosures />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => ['individualClosures', 'floorClosures', 'managerClosures'].includes(x.feature))?.permissions.canCreateAny) &&
        <Route path='/dayClosures/new' exact>
          <NewDayClosure />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => ['individualClosures', 'floorClosures', 'managerClosures'].includes(x.feature))?.permissions.canReadAny) &&
        <Route path='/dayClosures/:closureId' exact>
          <DayClosure />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'discounts')?.permissions.canReadAny) &&
        <Route path='/discounts' exact>
          <Discounts />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('employees') ||
        featurePermissions?.find(x => x.feature === 'employees')?.permissions.canReadAny) &&
        <Route path='/employees' exact>
          <Employees />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canCreate.includes('employees') ||
        featurePermissions?.find(x => x.feature === 'employees')?.permissions.canCreateAny) &&
        <Route path='/employees/new' exact>
          <NewEmployee />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('employees') ||
        featurePermissions?.find(x => x.feature === 'employees')?.permissions.canReadAny) &&
        <Route path='/employees/:employeeId' exact>
          <Employee />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('energyMeters')) &&
        <Route path='/energyMeters' exact>
          <EnergyMeters />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('energyMeters')) &&
        <Route path='/energyMeters/:energyMeterId' exact>
          <EnergyMeter />
        </Route>
      }
      <Route path='/externalCandidates/:externalCandidateId' exact>
        <ExternalCandidate />
      </Route>
      {(isAdmin || masterDataPermissions?.canRead.includes('foodDeliveryServices')) &&
        <Route path='/foodDeliveryServices' exact>
          <FoodDeliveryServices />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('foodDeliveryServices')) &&
        <Route path='/foodDeliveryServices/:foodDeliveryServiceId' exact>
          <FoodDeliveryService />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'fundTransfers')?.permissions.canReadAny) &&
        <Route path='/fundTransfers' exact>
          <FundTransfers />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('fxRates')) &&
        <Route path='/fxRates' exact>
          <FxRates />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('hrDocuments')) &&
        <Route path='/hrDocumentFiles/:hrDocumentFileId' exact>
          <HrDocumentFile />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('hrDocuments')) &&
        <Route path='/hrDocumentFiles' exact>
          <HrDocumentFiles />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canCreate.includes('hrDocuments')) &&
        <Route path='/hrDocumentTemplates/new' exact>
          <NewHrDocumentTemplate />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('hrDocuments')) &&
        <Route path='/hrDocumentTemplates/:hrDocumentTemplateId' exact>
          <HrDocumentTemplate />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('hrDocuments')) &&
        <Route path='/hrDocumentTemplates' exact>
          <HrDocumentTemplates />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('hrDocuments')) &&
        <Route path='/hrDocumentTypes' exact>
          <HrDocumentTypes />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('hrDocuments')) &&
        <Route path='/hrDocumentTypes/:hrDocumentTypeId' exact>
          <HrDocumentType />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'incomingRecords')?.permissions.canReadAny) &&
        <Route path='/incomingRecords' exact>
          <IncomingRecords />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'internalTransfers')?.permissions.canReadAny) &&
        <Route path='/internalTransfers' exact>
          <InternalTransfers />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'inventories')?.permissions.canReadAny) &&
        <Route path='/inventories' exact>
          <Inventories />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('jobBuckets')) &&
        <Route path='/jobBuckets' exact>
          <JobBuckets />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('jobBuckets')) &&
        <Route path='/jobBuckets/:jobBucketId' exact>
          <JobBucket />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('jobGroups')) &&
        <Route path='/jobGroups' exact>
          <JobGroups />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('jobGroups')) &&
        <Route path='/jobGroups/:jobGroupId' exact>
          <JobGroup />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('jobTitles')) &&
        <Route path='/jobTitles' exact>
          <JobTitles />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('jobTitles')) &&
        <Route path='/jobTitles/:jobTitleId' exact>
          <JobTitle />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('multiSportCards')) &&
        <Route path='/multiSportCards' exact>
          <MultiSportCards />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'operationalFinances')?.permissions.canReadAny) &&
        <Route path='/operationalFinances' exact>
          <OperationalFinances />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'outgoingRecords')?.permissions.canReadAny) &&
        <Route path='/outgoingRecords' exact>
          <OutgoingRecords />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('outlets')) &&
        <Route path='/outlets' exact>
          <Outlets />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canCreate.includes('outlets')) &&
        <Route path='/outlets/new' exact>
          <NewOutlet />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('outlets')) &&
        <Route path='/outlets/:outletId' exact>
          <Outlet />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('outletAreas')) &&
        <Route path='/outletAreas' exact>
          <OutletAreas />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('outletAreas')) &&
        <Route path='/outletAreas/:outletAreaId' exact>
          <OutletArea />
        </Route>
      }
      <Route path='/outletExports' exact>
        <OutletExports />
      </Route>
      <Route path='/outletDatedExports' exact>
        <OutletDatedExports />
      </Route>
      {(isAdmin || masterDataPermissions?.canRead.includes('paymentServices')) &&
        <Route path='/paymentServices' exact>
          <PaymentServices />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('paymentServices')) &&
        <Route path='/paymentServices/:paymentServiceId' exact>
          <PaymentService />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('periods')) &&
        <Route path='/periods' exact>
          <Periods />
        </Route>
      }
      {isAdmin &&
        <Route path='/plumbing' exact>
          <Plumbing />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'preshifts')?.permissions.canReadAny) &&
        <Route path='/preshifts' exact>
          <Preshifts />
        </Route>
      }
      <Route path='/reportAmountsPaid' exact>
        <ReportAmountsPaid />
      </Route>
      {(isAdmin || featurePermissions?.find(x => x.feature === 'operationalFinances')?.permissions.canReadAny) &&
        <Route path='/reportCosts' exact>
          <ReportCosts />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'retroactiveChanges')?.permissions.canReadAny) &&
        <Route path='/retroactiveChanges' exact>
          <RetroactiveChanges />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('roles')) &&
        <Route path='/roles' exact>
          <Roles />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canCreate.includes('roles')) &&
        <Route path='/roles/new' exact>
          <NewRole />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('roles')) &&
        <Route path='/roles/:roleId' exact>
          <Role />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('roles')) &&
        <Route path='/rolesX' exact>
          <RolesX />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canCreate.includes('roles')) &&
        <Route path='/rolesX/new' exact>
          <NewRoleX />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('roles')) &&
        <Route path='/rolesX/:roleId' exact>
          <RoleX />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('salarySchemes')) &&
        <Route path='/salarySchemes' exact>
          <SalarySchemes />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('salarySchemes')) &&
        <Route path='/salarySchemes/:salarySchemeId' exact>
          <SalaryScheme />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'settlements')?.permissions.canReadAny) &&
        <Route path='/settlements' exact>
          <Settlements />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('shiftLengths')) &&
        <Route path='/shiftLengths' exact>
          <ShiftLengths />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('shiftLengths')) &&
        <Route path='/shiftLengths/:shiftLengthId' exact>
          <ShiftLength />
        </Route>
      }
      <Route path='/support' exact>
        <Support />
      </Route>
      {(isAdmin || featurePermissions?.find(x => x.feature === 'teamFundTransfers')?.permissions.canReadAny) &&
        <Route path='/teamFundTransfers' exact>
          <TeamFundTransfers />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('terminalDevices')) &&
        <Route path='/terminalDevices' exact>
          <TerminalDevices />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('terminalDevices')) &&
        <Route path='/terminalDevices/:terminalDeviceId' exact>
          <TerminalDevice />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'timesheets')?.permissions.canReadAny) &&
        <Route path='/timesheets' exact>
          <Timesheets />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'tipsAllocations')?.permissions.canReadAny) &&
        <Route path='/tipsAllocations' exact>
          <TipsAllocations />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'tipsAllocations')?.permissions.canReadAny) &&
        <Route path='/tipsAllocations/:tipsAllocationId' exact>
          <TipsAllocation />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('users')) &&
        <Route path='/users' exact>
          <Users />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canCreate.includes('users')) &&
        <Route path='/users/new' exact>
          <NewUser />
        </Route>
      }
      <Route path='/users/profile' exact>
        <Profile />
      </Route>
      {(isAdmin || masterDataPermissions?.canRead.includes('users')) &&
        <Route path='/users/:userId' exact>
          <User />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'utilities')?.permissions.canReadAny) &&
        <Route path='/utilities' exact>
          <Utilities />
        </Route>
      }
      {(isAdmin || featurePermissions?.find(x => x.feature === 'vouchers')?.permissions.canReadAny) &&
        <Route path='/vouchers' exact>
          <Vouchers />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('voucherAmounts')) &&
        <Route path='/voucherAmounts' exact>
          <VoucherAmounts />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('voucherAmounts')) &&
        <Route path='/voucherAmounts/:voucherAmountId' exact>
          <VoucherAmount />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('weeklyHoursAverages')) &&
        <Route path='/weeklyHoursAverages' exact>
          <WeeklyHoursAverages />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('weeklyHoursAverages')) &&
        <Route path='/weeklyHoursAverages/:weeklyHoursAverageId' exact>
          <WeeklyHoursAverage />
        </Route>
      }
      <Route path='/workAnniversaries' exact>
        <WorkAnniversaries />
      </Route>
      {(isAdmin || masterDataPermissions?.canRead.includes('workTeams')) &&
        <Route path='/workTeams' exact>
          <WorkTeams />
        </Route>
      }
      {(isAdmin || masterDataPermissions?.canRead.includes('workTeams')) &&
        <Route path='/workTeams/:workTeamId' exact>
          <WorkTeam />
        </Route>
      }
      <Redirect to='/' />
    </Switch>
    :
    <Switch>
      <Route path='/' exact>
        <Login />
      </Route>
      <Route path='/login' exact>
        <Login />
      </Route>
      <Route path='/reset/:token' exact>
        <NewPassword />
      </Route>
      <Route path='/passwordReset' exact>
        <PasswordReset />
      </Route>
      <Route path='/support' exact>
        <Support />
      </Route>
      <Route path='/externalCandidates/:externalCandidateId' exact>
        <ExternalCandidate />
      </Route>
      <Redirect to='/' />
    </Switch>;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<LoadingSpinner message=''/>}>
          <NavigationBar />
          <main data-theme={userSettings ? (userSettings.isDarkMode ? 'dark' : 'light') : (defaultDark ? 'dark' : 'light')}
            className={styles['main-container']}>
            {routes}
          </main>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
